import React, { useState, useEffect } from 'react';
import componentStyles from './actionBar.module.css';
import eventBus from '../../service/eventBus';
import { EVENT_UPDATE_ACTION_BAR_VIEW, EVENT_UPDATE_AUTHENTICATED } from '../../utils/constants';
import {FaAlignJustify, FaSearch, FaCog, FaUser, FaStream, FaDoorOpen} from 'react-icons/fa';

const ActionBar = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleViewChange = (view) => {
        console.log('Action Bar: handleViewChange', view);
        eventBus.emit(EVENT_UPDATE_ACTION_BAR_VIEW, view);
    }

    const handleAuthenticated = (authenticated) => {
        console.log('Action Bar: handleAuthenticated', authenticated);
        setIsAuthenticated(authenticated);
    }

    useEffect(() => {
        // eventBus.on(EVENT_UPDATE_ACTION_BAR_VIEW, handleViewChange);
        eventBus.on(EVENT_UPDATE_AUTHENTICATED, handleAuthenticated);

        return () => {
            // eventBus.off(EVENT_UPDATE_ACTION_BAR_VIEW);
            eventBus.off(EVENT_UPDATE_AUTHENTICATED);
        };
    }, []);

    return (
        <div className={componentStyles.actionBar}>
            {isAuthenticated ? (
                <>
                    <button onClick={() => handleViewChange('feed')}> <FaStream /> </button>
                    <button onClick={() => handleViewChange('search')}> <FaSearch /> </button>
                    <button onClick={() => handleViewChange('settings')}> <FaCog /> </button>
                    <button onClick={() => handleViewChange('profile')}> <FaUser /> </button>
                </>
            ) : (
                <>
                    <button onClick={() => handleViewChange('login')}> <FaDoorOpen /> </button>
                </>
            )}
        </div>
    )
}

export default ActionBar;