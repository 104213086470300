import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Chat from './chat';
import StatsTable from '../tables/statsTable';
import SliderView from './sliderView';
import { getColumnName } from '../../utils/columnUtils';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import globalStyles from '../globals.module.css';
import componentStyles from './inDepthBetCard.module.css';
import NoImage from '../global/noImage';
import eventBus from '../../service/eventBus';
import { 
    EVENT_TRIGGER_IN_DEPTH_BET_CARD,
    EVENT_UPDATE_IN_DEPTH_BET_CARD,
    EVENT_FEED_LOCKED
} from '../../utils/constants';
import { FaBackspace } from 'react-icons/fa';


const InDepthBetCard = () => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    const [betCardData, setBetCardData] = useState(null);
    const [matchDetails, setMatchDetails] = useState((!!betCardData && betCardData.matchDetails && betCardData.matchDetails.length > 0) 
        ? betCardData.matchDetails 
        : []); // State variable to store fetched bet card statistics data

    const [statCols, setStatCols] = useState((!!matchDetails && matchDetails.length > 0) ? Object.keys(matchDetails[0]) : []);
    const [selectedStat, setSelectedStat] = useState('');
    const [selectedStatValues, setSelectedStatValues] = useState([]);
    const [selectedSeasonRange, setSelectedSeasonRange] = useState('');
    const [selectedStatRange, setSelectedStatRange] = useState(0);
    const [selectedStatThreshold, setSelectedStatThreshold] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // Simulate a 3 second timeout to fetch bet data
    const fetchBetDataWithTimeout = async () => {
        // Simulate a delay of 3 seconds
        await new Promise(resolve => setTimeout(resolve, 500));
        // let betDataCols = Object.keys(bet.matchDetails[0]);
        // setStatCols(betDataCols);
        // // Fetch bet data and set it to state
        // // const mockData = generateMockData(betCardData.playerId); // Generate mock data for selected player
        // setBetCardStatsData(betCardData.matchDetails); // Store the mock data in state
        console.log('Fetched Bet Card Stats Data:', matchDetails); // Log the fetched data
        setIsLoading(false);
    };

    const handleStatChange = (e) => {
        setSelectedStat(e.target.value);
        const values = matchDetails.map(match => match[e.target.value]);
        setSelectedStatValues(values);
    }

    const handleStatRangeChange = (e) => {
        setSelectedStatRange(e.target.value);
    }

    const handleSeasonRangeChange = (e) => {
        setSelectedSeasonRange(e.target.value);
    };

    const handleStatThresholdChange = (e) => {
        setSelectedStatThreshold(e.target.value);
    };

    const handleClose = () => {
        console.log(`Event ${EVENT_TRIGGER_IN_DEPTH_BET_CARD} triggered with value`, false);
        eventBus.emit(EVENT_TRIGGER_IN_DEPTH_BET_CARD, false);
        // setIsLoading(true);
        setBetCardData(null);
        setSelectedStatRange(0);
        setSelectedStatThreshold(null);
        eventBus.emit(EVENT_FEED_LOCKED, false);
    };

    const fetchBetData = async () => {
        // const response = await fetch('https://api.example.com/bet-data');
        // const data = await response.json();
        // console.log(data);
        // Function to generate mock basketball statistics data
    };

    useEffect(() => {
        // fetchBetDataWithTimeout(); // Call the function to fetch bet data with timeout
        eventBus.on(EVENT_UPDATE_IN_DEPTH_BET_CARD, (betCard) => {
            console.log(`Event ${EVENT_UPDATE_IN_DEPTH_BET_CARD} received with value`, betCard);
            setBetCardData(betCard);
            // setMatchDetails(betCard.matchDetails);
            // setStatCols(Object.keys(betCard.matchDetails[0]));

            // setIsLoading(false);
        });
        setIsLoading(false);
    }, []);

    const renderBarChart = () => {
        const threshold = 15; // Define a threshold value
        // Prepare data for the bar chart based on selectedStat and selectedStatRange
        const dataValues = selectedStatValues.slice(-selectedStatRange); // Slice from the last value until the range
        const backgroundColors = dataValues.map(value => selectedStatThreshold === null ? 'rgba(75, 192, 192, 0.6)' : (value > selectedStatThreshold ? 'rgba(75, 192, 192, 0.6)'  : 'rgba(255, 99, 132, 0.6)'));
        const chartData = {
            labels: Array.from({ length: dataValues.length }, (_, i) => i + 1), // Update labels based on the length of dataValues
            datasets: [
                {
                    label: selectedStat, // Label for the dataset
                    data: dataValues, // Example data, replace with actual data
                    backgroundColor: backgroundColors, // Color of the bars based on threshold
                    borderColor: 'rgba(75, 192, 192, 1)', // Border color of the bars
                    borderWidth: 1, // Width of the border
                },
            ],
        };

        // Options for the bar chart
        const options = {
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom',
                },
                y: {
                    beginAtZero: true, // Start y-axis at zero
                },
            },
        };

        return (
            <div>
                <Bar data={chartData} options={options} /> {/* Render the bar chart */}
            </div>
        );
    }

    const displayMatchDetailslsTable = () => {
        // const matchDetailsValues = betCardData.matchDetails.map(x => Object.values(x));
        return (matchDetails &&
            <StatsTable
                columns={statCols}
                rows={matchDetails}
            />
        )
    }

    const renderSliderView = () => {
        const buildMatchDetailsSlider = () => {
            return (
                <div className={componentStyles.betDataSubSection} id="matchDetails">
                    {displayMatchDetailslsTable()}
                </div>
            )
        }
        const buildHitRateSlider = () => {
            return (
                <div className={componentStyles.betDataSubSection} id="statsChart">
                    <div className={componentStyles.statsChartSelection}>
                        <select className={globalStyles.slight3dEffect} onChange={handleSeasonRangeChange}>
                            <option value="current">Season</option>
                            <option value="all">All</option>
                        </select>
                        <select className={globalStyles.slight3dEffect} onChange={handleStatChange}>
                            <option value="">Stat</option>
                            {statCols.length > 0 && statCols.map((stat, index) => (
                                <option key={index} value={stat}>{getColumnName(stat)}</option>
                            ))}
                        </select>
                        <select className={globalStyles.slight3dEffect} onChange={handleStatRangeChange}>
                            <option value="">Range</option>
                            {selectedStatValues.length > 0 &&
                                Array.from({ length: matchDetails.length }, (_, index) => index).map((stat, index) => (
                                    <option key={`${index}-${stat}`} value={stat}>{stat}</option>
                                ))
                            }
                        </select>
                        <select className={globalStyles.slight3dEffect} onChange={handleStatThresholdChange}>
                            <option value="">Threshold</option>
                            {selectedStatValues.length > 0 &&
                                Array.from({ length: Math.floor(Math.max(...selectedStatValues) / 5) + 1 }, (_, index) => index * 5).map((stat, index) => (
                                    <option key={`${index}-${stat}`} value={stat}>{stat}</option>
                                ))
                            }
                        </select>
                    </div>
                    {(selectedStat !== '' && selectedStatRange !== '' && selectedStatThreshold !== '') && (
                        <div className={componentStyles.statThresholdContainer}>
                            <div className={componentStyles.betDataSubSection}>
                                <p>Stats</p>
                                {renderBarChart()}
                            </div>
                        </div>
                    )}
                </div>
            )
        }
        const buildScoutingReportSlider = () => {
            return (
                <div className={componentStyles.betDataSubSection} id="scoutingReport">
                    <p style={{color: 'var(--bloomberg-orange)'}}>Scouting Report:</p>
                    {/*TODO: Update value in API*/}
                    <textarea className={componentStyles.inDepthBetCardScoutingReportTextarea} disabled={true}>{betCardData['scouting_report']}</textarea> 
                </div>
            )
        }
        const buildChatSlider = () => {
            return (
                <div className={componentStyles.betDataSubSection} id="chat">
                    <Chat />
                </div>
            )
        }
        const sliderViews = [buildScoutingReportSlider()]
        // const sliderViews = [buildMatchDetailsSlider(), buildHitRateSlider(), buildScoutingReportSlider()]
        // const sliderViews = [buildMatchDetailsSlider(), buildHitRateSlider(), buildScoutingReportSlider(), buildChatSlider()]
        
        return (
            <SliderView sliderViews={sliderViews} />
        )
    }

    //render view
    if (betCardData === null) {
    // if (isLoading) {
        return (
            <></>
            // <div className={styles.betDataCardModalContainer}>
            //     <div className={styles.loadingContainer}>Loading...</div>
            // </div>
        )
    } else {
        return (
            <div className={componentStyles.betDataCardModalContainer}>
                <div className={componentStyles.betDataCardContainer}>
                    <div className={componentStyles.betDataCardHeader}>
                        <div className={componentStyles.betDataCardHeaderClose}>
                            <button className={globalStyles.smallBtn} onClick={handleClose}>
                                <FaBackspace />
                            </button>
                        </div>
                        <div className={componentStyles.betDataCardPlayerInfo}>
                            <div>
                                <p>{betCardData.name}</p>
                                <p>{!!betCardData.prediction ? betCardData.prediction : 'N/A'}</p>
                                <p>{betCardData.upcomingEvent}</p>
                            </div>
                            <div>
                                {betCardData.imgUrl ? (
                                    <img src={betCardData.imgUrl} alt={betCardData.playerName} />
                                ) : (
                                    <NoImage />
                                )}
                            </div>
                            
                            
                        </div>
                    </div>
                    <div className={componentStyles.betDataCardBody}>
                        {renderSliderView()}
                    </div>
                </div>                
            </div>
        )
    }
}

export default InDepthBetCard;