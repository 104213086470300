import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from "react-router-dom";
import Home from './components/home/home';
import Waitlist from './components/waitlist/waitlist';
import Login from './components/login/login';
import Register from './components/login/register';
import Banner from './components/banner/banner';
import Footer from './components/footer/footer';
import AppState from './model/AppState';
import ActionBar from './components/actionBar/actionBar';
import Notification from './components/notification/notification';
import InDepthBetCard from './components/betFeed/inDepthBetCard';
import eventBus from './service/eventBus';
import { EVENT_TRIGGER_IN_DEPTH_BET_CARD } from './utils/constants';
function App() {
    const [showWaitlistOnly, setShowWaitlistOnly] = useState(false);
    const [isBetDataCardOpen, setIsBetDataCardOpen] = useState(false);

    const adjustBodyHeight = () => {
        console.log('adjusting body height');
        const windowInnerHeight = window.innerHeight;
        document.body.style.height = `${windowInnerHeight}px`;
    }
    
    useEffect(() => {
        eventBus.on(EVENT_TRIGGER_IN_DEPTH_BET_CARD, (isOpen) => {
            console.log(`Event ${EVENT_TRIGGER_IN_DEPTH_BET_CARD} received with value`, isOpen);
            setIsBetDataCardOpen(isOpen);
        });

        // adjustBodyHeight();
        // window.addEventListener('resize', adjustBodyHeight);
        return () => {
            window.removeEventListener('resize', adjustBodyHeight);
        };
    }, []);

    return (
        <div className={'App'}>
            <Notification />
            <Router>
                <Banner />
                <Routes>
                    {showWaitlistOnly ? (
                        <Route path="/waitlist" element={<Waitlist />} />
                    ) : (
                        <>
                            <Route path="/" element={<Home />} />
                            <Route path="/home" element={<Home />} />
                            {/* <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} /> */}
                        </>
                    )}
                    <Route path="*" element={<Navigate to={showWaitlistOnly ? "/waitlist" : "/home"} />} />                    
                </Routes>
                <ActionBar />
                <InDepthBetCard />
                {/* {isBetDataCardOpen && <InDepthBetCard />} */}
            </Router>
            <Notification/>
        </div>
    );
}

export default App;
