import React, { useEffect, useState } from 'react';
import globalStyles from '../globals.module.css'; // Import CSS for notifications
import eventBus from '../../service/eventBus';
import { EVENT_NOTIFICATION_PUSH } from '../../utils/constants';

const Notification = () => {
    const [notification, setNotification] = useState(null); // State to hold the current notification
    // Effect to handle notification display
    useEffect(() => {
        eventBus.on(EVENT_NOTIFICATION_PUSH, (notification) => {
            setNotification(notification);
            setTimeout(() => {
                setNotification(null);
            }, 5000);
        });
    }, [notification]);

    if (!notification) return null; // Return null if no notification

    return (
        <div className={globalStyles.notificationContainer}>
            {notification && <div className={`${globalStyles.notification} ${globalStyles[notification.type]}`}>
                {notification.message}
            </div>}
        </div>
    );
};

export default Notification; 