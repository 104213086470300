import React, { useState } from 'react';
import { joinWaitlist } from '../../service/apiService'; // Import the joinWaitlist function
import globalStyles from '../globals.module.css';

// Waitlist component to manage user sign-ups for a waitlist
const Waitlist = () => {
    // State to hold the email input by the user
    const [email, setEmail] = useState('');
    // State to manage the submission status
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false); // State for success notification

    // Function to handle the input change
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        try {
            let response = await joinWaitlist(email); // Call the joinWaitlist API
            const { data } = response;
            if (data.success) {
                setSubmitted(true); // Update the submission status
                setSuccess(true); // Show success notification
            } else {
                setError('Failed to join the waitlist. Please try again.'); // Set error message
                console.error(data.error);
                setSubmitted(false);
            }
        } catch (err) {
            setError('Failed to join the waitlist. Please try again.'); // Set error message
            console.error(err);
            setSubmitted(false);
        }
    };

    return (
        <div className={globalStyles.fullPageContainer} id={'waitlist'}>
            {submitted ? (
                <p>Thank you for joining the waitlist!</p>
            ) : (
                <div className={''}>
                    <div className={''}>
                        <h2>Join Our Waitlist</h2>
                    </div>
                    <div className={globalStyles.simpleForm}>
                        <div className={globalStyles.simpleFormRow}>
                            <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" required />
                        </div>
                        <div className={globalStyles.simpleFormRow}>
                            <button className={globalStyles.primaryBtn} onClick={handleSubmit}>
                                Join
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Waitlist;
