import { useState, useEffect } from 'react';
import { getUserSettings, updateUserSettings } from '../../service/apiService';
import componentStyles from './settings.module.css';
import globalStyles from '../globals.module.css';
import SessionService from '../../service/sessionService';
const Settings = () => {
    // State for all settings
    const [isSaving, setIsSaving] = useState(false);
    const [saveStatus, setSaveStatus] = useState('');
    const sessionService = SessionService.getInstance();
    const [settings, setSettings] = useState(sessionService.getUserSettings());
    // Fetch user settings on component mount
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const userId = sessionService.getUserId();
                const response = await getUserSettings(userId);
                const {data} = response;
                if (data.success) {
                    const { results } = data;
                    setSettings(results.userSettings);
                } else {
                    setSaveStatus('Error loading settings');
                }
            } catch (error) {
                setSaveStatus('Error loading settings');
            }
        };
        fetchSettings();
    }, []);

    // Handle sports toggle
    const handleSportToggle = (sport) => {
        console.log(`handleSportToggle: ${sport}`);
        setSettings(prev => ({
            ...prev,
            sports: {
                ...prev.sports,
                [sport]: !prev.sports[sport]
            }
        }));
    };

    // Handle bet type toggle
    const handleBetTypeToggle = (betType) => {
        console.log(`handleBetTypeToggle: ${betType}`);
        setSettings(prev => ({
            ...prev,
            betTypes: {
                ...prev.betTypes,
                [betType]: !prev.betTypes[betType]
            }
        }));
    };

    // Handle feed preferences change
    const handleFeedPreferenceChange = (key, value) => {
        console.log(`handleFeedPreferenceChange: ${key} ${value}`);
        setSettings(prev => ({
            ...prev,
            feedPreferences: {
                ...prev.feedPreferences,
                [key]: value
            }
        }));
    };

    // Handle notification toggle
    const handleNotificationToggle = (key) => {
        console.log(`handleNotificationToggle: ${key}`);
        setSettings(prev => ({
            ...prev,
            notifications: {
                ...prev.notifications,
                [key]: !prev.notifications[key]
            }
        }));
    };

    // Save settings
    const handleSave = async () => {
        setIsSaving(true);
        setSaveStatus('');
        try {
            const userId = sessionService.getUserId();
            const response = await updateUserSettings(userId, settings);
            if (response.data.success) {
                setSaveStatus('Settings saved successfully!');
                sessionService.setUserSettings(settings);
            }
        } catch (error) {
            setSaveStatus('Error saving settings');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className={componentStyles.settingsContainer}>
            <h3>Settings</h3>
            
            {/* Sports Section */}
            <section className={componentStyles.settingsSection + ' ' + globalStyles.slight3dEffect}>
                <h2>Sports Preferences</h2>
                <div className={componentStyles.checkboxGrid}>
                    {Object.entries(settings.sports).map(([sport, isEnabled]) => (
                        <label key={sport} className={componentStyles.checkboxLabel}>
                            <input
                                type="checkbox"
                                checked={isEnabled}
                                onChange={() => handleSportToggle(sport)}
                            />
                            {sport.toUpperCase()}
                        </label>
                    ))}
                </div>
            </section>

            {/* Bet Types Section */}
            <section className={componentStyles.settingsSection + ' ' + globalStyles.slight3dEffect}>
                <h2>Bet Types</h2>
                <div className={componentStyles.checkboxGrid}>
                    {Object.entries(settings.betTypes).map(([betType, isEnabled]) => (
                        <label key={betType} className={componentStyles.checkboxLabel}>
                            <input
                                type="checkbox"
                                checked={isEnabled}
                                onChange={() => handleBetTypeToggle(betType)}
                            />
                            {betType.replace(/([A-Z])/g, ' $1').trim()}
                        </label>
                    ))}
                </div>
            </section>

            {/* Feed Preferences Section */}
            <section className={componentStyles.settingsSection + ' ' + globalStyles.slight3dEffect}>
                <h2>Feed Preferences</h2>
                <div className={componentStyles.inputGroup}>
                    <label>
                        Items per page:
                        <select
                            value={settings.feedPreferences.itemsPerPage}
                            onChange={(e) => handleFeedPreferenceChange('itemsPerPage', Number(e.target.value))}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </label>
                    <label className={componentStyles.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={settings.feedPreferences.autoRefresh}
                            onChange={() => handleFeedPreferenceChange('autoRefresh', !settings.feedPreferences.autoRefresh)}
                        />
                        Auto-refresh feed
                    </label>
                    {settings.feedPreferences.autoRefresh && (
                        <label>
                            Refresh interval (minutes):
                            <select
                                value={settings.feedPreferences.refreshInterval}
                                onChange={(e) => handleFeedPreferenceChange('refreshInterval', Number(e.target.value))}
                            >
                                <option value={1}>1</option>
                                <option value={5}>5</option>
                                <option value={15}>15</option>
                                <option value={30}>30</option>
                            </select>
                        </label>
                    )}
                </div>
            </section>

            {/* Notifications Section */}
            <section className={componentStyles.settingsSection + ' ' + globalStyles.slight3dEffect}>
                <h2>Notifications</h2>
                <div className={componentStyles.checkboxGrid}>
                    {Object.entries(settings.notifications).map(([key, isEnabled]) => (
                        <label key={key} className={componentStyles.checkboxLabel}>
                            <input
                                type="checkbox"
                                checked={isEnabled}
                                onChange={() => handleNotificationToggle(key)}
                            />
                            {key.replace(/([A-Z])/g, ' $1').trim()}
                        </label>
                    ))}
                </div>
            </section>

            {/* Save Button */}
            <div className={componentStyles.saveSection}>
                <button 
                    className={globalStyles.primaryBtn}
                    onClick={handleSave}
                    disabled={isSaving}
                >
                    {isSaving ? 'Saving...' : 'Save Settings'}
                </button>
                {saveStatus && (
                    <p className={componentStyles.saveStatus}>
                        {saveStatus}
                    </p>
                )}
            </div>
        </div>
    );
};

export default Settings;