// apiService.js

import axios from 'axios'; // Import axios

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const POST = (url, data) => {
    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

// Function to handle user login
export const login = async (email, password) => {
    try {
        const response = await POST(`${BASE_URL}/user/login`, {
            email,
            password,
        });
        return response; // Return the entire response object
    } catch (error) {
        console.error('Error during login:', error);
        throw error; // Rethrow the error for further handling
    }
};

// Function to handle user registration
export const register = async (email, password) => {
    try {
        const response = await POST(`${BASE_URL}/user/register`, {
            email,
            password,
        });
        return response; // Return the entire response object
    } catch (error) {
        console.error('Error during registration:', error);
        throw error; // Rethrow the error for further handling
    }
};

// Function to join the waitlist
export const joinWaitlist = async (email) => {
    try {
        const response = await POST(`${BASE_URL}/user/waitlist`, {
            email,
        });
        return response; // Return the entire response object
    } catch (error) {
        console.error('Error joining waitlist:', error);
        throw error; // Rethrow the error for further handling
    }
};

export const authenticateUser = async (sessionId) => {
    try {
        const response = await POST(`${BASE_URL}/user/authenticate`, {
            'sessionToken': sessionId,
        });
        return response; // Return the entire response object
    } catch (error) {
        console.error('Error authenticating user:', error);
        throw error; // Rethrow the error for further handling
    }
}

export const logout = async () => {
    try {
        const response = await POST(`${BASE_URL}/user/logout`);
        return response; // Return the entire response object
    }
    catch (error) {
        console.error('Error logging out:', error);
        throw error; // Rethrow the error for further handling
    }
}


export const getFeed = async (request) => {
    try {
        const response = await POST(`${BASE_URL}/feed`, request);
        return response; // Return the entire response object
    }
    catch (error) {
        console.error('Error fetching feed:', error);
        throw error; // Rethrow the error for further handling
    }
}

export const getFeedSearch = async (searchTerm) => {
    try {
        const response = await POST(`${BASE_URL}/feed/search`, {
            'searchTerm': searchTerm
        });
        return response; // Return the entire response object
    }
    catch (error) {
        console.error('Error fetching feed search:', error);
        throw error; // Rethrow the error for further handling
    }
}

export const getOdds = async (request) => {
    try {
        const response = await POST(`${BASE_URL}/odds`, request);
        return response; // Return the entire response object
    } 
    catch (error) {
        console.error('Error fetching odds:', error);
        throw error; // Rethrow the error for further handling
    }
}

// Function to get user settings
export const getUserSettings = async (userId) => {
    const request = {
        'userId': userId
    }
    try {
        const response = await POST(`${BASE_URL}/user/settings`, request);
        return response;
    } catch (error) {
        console.error('Error fetching user settings:', error);
        throw error;
    }
};


// Function to update user settings
export const updateUserSettings = async (userId, settings) => {
    try {
        const request = {
            'userId': userId,
            'userSettings': settings
        }
        const response = await POST(`${BASE_URL}/user/settings/update`, request);
        return response;
    } catch (error) {
        console.error('Error updating user settings:', error);
        throw error;
    }
};

// Function to get user profile
export const getUserProfile = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/user/profile`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

// Function to update user profile
export const updateUserProfile = async (profileData) => {
    try {
        const response = await POST(`${BASE_URL}/user/profile/update`, profileData);
        return response;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
};

// Function to update user tier
export const updateUserTier = async (tierData) => {
    try {
        const response = await POST(`${BASE_URL}/user/tier/update`, tierData);
        return response;
    } catch (error) {
        console.error('Error updating user tier:', error);
        throw error;
    }
};

// Additional API functions can be added here 
