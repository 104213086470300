import React from 'react';
import componentStyles from './banner.module.css'; // Import the CSS file for styling
import AppState from '../../model/AppState'; // Import AppState to check user authentication

// Banner component to display the site name
const Banner = () => {
    const handleRedirect = () => {
        // Redirect to the appropriate page based on authentication status
        window.location.href = '/'; // Redirect to home if not logged in
        // if (AppState.isUserAuthenticated()) {
        //     window.location.href = '/user-home'; // Redirect to user home if logged in
        // } else {
        //     window.location.href = '/home'; // Redirect to home if not logged in
        // }
    };

    return (
        <div className={componentStyles.banner}>
            <h1 onClick={handleRedirect} style={{ cursor: 'pointer' }}>Statspyder</h1>
            {/* TODO: we want to display different banners based on mobile or desktop */}
            {/* <h1 onClick={handleRedirect} style={{ cursor: 'pointer' }}>GTerminal by Statspyder</h1> */}
        </div>
    );
};

export default Banner; 