import { useState, useEffect } from 'react';
import { getFeedSearch } from '../../service/apiService';
import BetCard from '../betFeed/betCard';
import componentStyles from './search.module.css';

const Search = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage] = useState(1);
    const [pageSize] = useState(25);

    // Debounce search to prevent too many API calls
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                handleSearch();
            } else {
                setSearchResults([]);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await getFeedSearch(searchTerm);
            const { data } = response;

            if (data.success) {
                setSearchResults(data.results.feedItems.map((item, index) => ({
                    ...item,
                    id: index + 1
                })));
            }
        } catch (error) {
            console.error('Error searching bets:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className={componentStyles.searchContainer}>
            <h3>Search</h3>
            <div className={componentStyles.searchBarContainer}>
                <input
                    type="text"
                    placeholder="Search bets..."
                    value={searchTerm}
                    onChange={handleInputChange}
                    className={componentStyles.searchInput}
                />
            </div>
            <div className={componentStyles.searchResults}>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    searchResults.map((bet) => (
                        <BetCard key={bet.id} betCard={bet} />
                    ))
                )}
            </div>
        </div>
    );
};

export default Search;
