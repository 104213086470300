class EventBus {
    constructor() {
        this.listeners = {};
    }

    // Register an event listener
    on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        console.log('on', event, callback);
        this.listeners[event].push(callback);
    }

    // Deregister an event listener
    off(event, callback) {
        if (!this.listeners[event]) return;
        console.log('off', event, callback);
        this.listeners[event] = this.listeners[event].filter(listener => listener !== callback);
    }

    // Trigger an event
    emit(event, data) {
        if (!this.listeners[event]) return;
        console.log('emit', event, data);
        this.listeners[event].forEach(callback => callback(data));
    }
}

// Create a singleton instance of EventBus
const eventBus = new EventBus();
export default eventBus; 