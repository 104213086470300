import componentStyles from './betCard.module.css';
import globalStyles from '../globals.module.css';
import React from 'react';
import eventBus from '../../service/eventBus';
import NoImage from '../global/noImage';
import { 
    EVENT_TRIGGER_IN_DEPTH_BET_CARD, 
    EVENT_UPDATE_IN_DEPTH_BET_CARD, 
    EVENT_FEED_LOCKED } from '../../utils/constants';


const BetCard = ({ betCard }) => {
    const handleClick = () => {
        console.log(`Event ${EVENT_TRIGGER_IN_DEPTH_BET_CARD} triggered with value`, true);
        console.log(`Event ${EVENT_UPDATE_IN_DEPTH_BET_CARD} triggered with value`, betCard);
        eventBus.emit(EVENT_TRIGGER_IN_DEPTH_BET_CARD, true);
        eventBus.emit(EVENT_UPDATE_IN_DEPTH_BET_CARD, betCard);
        eventBus.emit(EVENT_FEED_LOCKED, true);
    }

    const teamPropBetCard = () => {
        return (
            <div className={componentStyles.card + ' ' + globalStyles.slight3dEffect} onClick={handleClick}>
                <div className={componentStyles.cardLeft}>
                    {betCard.imgUrl ? (
                        <img src={betCard.imgUrl}/>
                    ) : (
                        <NoImage />
                    )}
                </div>
                <div className={componentStyles.cardRight}>
                    {/* <h3>{betCard['name']}</h3> */}
                    <p>{betCard['matchUp']}</p>
                    <p>{betCard.prediction ? betCard.prediction : 'N/A'}</p>
                    <p>{betCard.upcomingEvent ? betCard.upcomingEvent : 'N/A'}</p>
                </div>
            </div>
        )

    }

    const playerPropBetCard = () => {
        return (
            <div className={componentStyles.card + ' ' + globalStyles.slight3dEffect} onClick={handleClick}>
                <div className={componentStyles.cardLeft}>
                    {betCard.imgUrl ? (
                        <img src={betCard.imgUrl} alt={betCard.playerName} />
                    ) : (
                        <NoImage />
                    )}
                </div>
                <div className={componentStyles.cardRight}>
                    <h3>{betCard.playerName}</h3>
                    <p>{betCard.matchup}</p>
                    <p>{betCard.prediction ? betCard.prediction : 'N/A'}</p>
                    <p>{betCard.upcomingEvent ? betCard.upcomingEvent : 'N/A'}</p>
                </div>
            </div>
        )

    }

    const mmaPropBetCard = () => {
        return (
            <div className={componentStyles.card + ' ' + globalStyles.slight3dEffect} onClick={handleClick}>
                <div className={componentStyles.cardLeft}>
                    {betCard.imgUrl ? (
                        <img src={betCard.imgUrl} alt={betCard.playerName} />
                    ) : (
                        <NoImage />
                    )}
                </div>
                <div className={componentStyles.cardRight}>
                    <h3>{betCard.fighterName}</h3>
                    <p>{betCard.matchup}</p>
                    <p>{betCard.prediction ? betCard.prediction : 'N/A'}</p>
                    <p>{betCard.upcomingEvent ? betCard.upcomingEvent : 'N/A'}</p>
                </div>
            </div>
        )
    }

    const getBetCardType = () => {
        switch(betCard.betType) {
            case 'team_prop':
                return teamPropBetCard();
            case 'player_prop':
                return playerPropBetCard();
            case 'mma_prop':
                return mmaPropBetCard();
        }
    }
    return (
        getBetCardType()
    )
}

export default BetCard;