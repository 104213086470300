import React, { useState } from 'react';
import { register } from '../../service/apiService';
import componentStyles from './register.module.css';
import globalStyles from '../globals.module.css';
import NotificationModel from '../../model/notificationModel';
import { NOTIFICATIONS_SUCCESS, NOTIFICATIONS_ERROR, EVENT_NOTIFICATION_PUSH, EVENT_UPDATE_ACTION_BAR_VIEW } from '../../utils/constants';
import eventBus from '../../service/eventBus';

const Register = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!emailPattern.test(formData.email)) {
            eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                new NotificationModel('Please enter a valid email address.', NOTIFICATIONS_ERROR)
            );
            return false;
        }

        if (formData.password.length < 8) {
            eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                new NotificationModel('Password must be at least 8 characters long.', NOTIFICATIONS_ERROR)
            );
            return false;
        }

        if (formData.password !== formData.confirmPassword) {
            eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                new NotificationModel('Passwords do not match.', NOTIFICATIONS_ERROR)
            );
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        try {
            const response = await register(formData.email, formData.password);
            const { data } = response;

            if (data.success) {
                eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                    new NotificationModel('Registration successful! Please login.', NOTIFICATIONS_SUCCESS)
                );
                eventBus.emit(EVENT_UPDATE_ACTION_BAR_VIEW, 'login');
            } else {
                eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                    new NotificationModel(data.error || 'Registration failed.', NOTIFICATIONS_ERROR)
                );
            }
        } catch (error) {
            eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                new NotificationModel('An error occurred during registration.', NOTIFICATIONS_ERROR)
            );
            console.error('Registration error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLoginClick = () => {
        eventBus.emit(EVENT_UPDATE_ACTION_BAR_VIEW, 'login');
    };

    return (
        <div className={componentStyles.registerContainer}>
            <div className={`${componentStyles.registerCard} ${globalStyles.slight3dEffect}`}>
                <h2>Create an Account</h2>
                <form onSubmit={handleSubmit} className={componentStyles.registerForm}>
                    <div className={componentStyles.formGroup}>
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            required
                            className={componentStyles.input}
                        />
                    </div>
                    <div className={componentStyles.formGroup}>
                        <label>Password</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Create a password"
                            required
                            className={componentStyles.input}
                        />
                    </div>
                    <div className={componentStyles.formGroup}>
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm your password"
                            required
                            className={componentStyles.input}
                        />
                    </div>
                    <button 
                        type="submit" 
                        className={globalStyles.primaryBtn}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Creating Account...' : 'Register'}
                    </button>
                </form>
                <div className={componentStyles.loginPrompt}>
                    <p>Already have an account?</p>
                    <button 
                        onClick={handleLoginClick}
                        className={globalStyles.secondaryBtn}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Register; 