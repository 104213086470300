const sessionMap = {
    'sessionId':null,
    'userId':null,
    'userSettings':null
}

class SessionService {
    static _instance = null;

    constructor() {
        if (SessionService._instance) {
            return SessionService._instance;
        }

        try {
            const statpyderSession = localStorage.getItem('statpyderSession');
            if (statpyderSession) {
                this.session = JSON.parse(statpyderSession);
            } else {
                this.session = { ...sessionMap };
                localStorage.setItem('statpyderSession', JSON.stringify(this.session));
            }
        } catch (error) {
            console.error('Error parsing statpyderSession', error);
            this.session = { ...sessionMap };
        }

        SessionService._instance = this;
    }

    static getInstance() {
        return !!SessionService._instance ? SessionService._instance : (SessionService._instance = new SessionService());
    }

    saveSession(sessionId) {
        this.session.sessionId = sessionId;
        this.persistSession();
    }

    setUser(user) {
        this.session.user = user;
        this.session.userId = user.id;
        this.persistSession();
    }

    getUser() {
        return this.session.user;
    }

    getUserId() {
        return this.session.userId;
    }

    getSessionId() {
        return this.session.sessionId;
    }

    setIsAuthenticated(isAuthenticated) {
        this.session.isAuthenticated = isAuthenticated;
        this.persistSession();
    }

    setUserSettings(userSettings) {
        this.session.userSettings = userSettings;
        this.persistSession();
    }
    

    isAuthenticated() {
        return !!this.session.sessionId && !!this.session.userId;
    }

    clearSession() {
        this.session = { ...sessionMap };
        this.persistSession();
    }

    persistSession() {
        localStorage.setItem('statpyderSession', JSON.stringify(this.session));
    }

    getSession() {
        return this.session;
    }

    getUserSettings() {
        return this.session.userSettings;
    }
}

export default SessionService;
