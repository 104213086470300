class AppState {
    constructor() {
        this.state = {
            user: null,
            isAuthenticated: false,
        };
        this.loadState(); // Load state from local storage on initialization
    }

    // Method to set user information and update authentication status
    setUser(user) {
        this.state.user = user;
        this.state.isAuthenticated = true;
        this.saveState(); // Save state to local storage
    }

    // Method to clear user information
    clearUser() {
        this.state.user = null;
        this.state.isAuthenticated = false;
        this.saveState(); // Save state to local storage
    }

    // Method to get user information
    getUser() {
        return this.state.user;
    }

    // Method to check if the user is authenticated
    isUserAuthenticated() {
        return this.state.isAuthenticated;
    }

    // Method to save state to local storage
    saveState() {
        localStorage.setItem('appState', JSON.stringify(this.state));
    }

    // Method to load state from local storage
    loadState() {
        const savedState = localStorage.getItem('appState');
        if (savedState) {
            this.state = JSON.parse(savedState);
        }
    }

    // Method to refresh state on page reload
    refreshState() {
        this.loadState(); // Reload state from local storage
    }
}

export default new AppState(); // Export a singleton instance of AppState 