import React, { useState, useRef, useEffect } from 'react';
import componentStyles from './sliderView.module.css';
import globalStyles from '../globals.module.css';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';

const SliderView = ({ sliderViews }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const startX = useRef(0);
    const endX = useRef(0);

    // const handleTouchStart = (e) => {
    //     startX.current = e.touches[0].clientX;
    // };

    // const handleTouchMove = (e) => {
    //     endX.current = e.touches[0].clientX;
    // };

    // const handleTouchEnd = () => {
    //     if (startX.current - endX.current > 200) {
    //         handleNext();
    //     } else if (endX.current - startX.current > 200) {
    //         handlePrevious();
    //     }
    // };

    // const handleMouseDown = (e) => {
    //     startX.current = e.clientX;
    // };

    // const handleMouseMove = (e) => {
    //     if (startX.current !== 0) {
    //         endX.current = e.clientX;
    //     }
    // };

    // const handleMouseUp = () => {
    //     if (startX.current !== 0) {
    //         if (startX.current - endX.current > 200) {
    //             handleNext();
    //         } else if (endX.current - startX.current > 200) {
    //             handlePrevious();
    //         }
    //         startX.current = 0;
    //         endX.current = 0;
    //     }
    // };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex === sliderViews.length - 1) {
                return 0;
            } else {
                return prevIndex + 1;
            }
        });
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex === 0) {
                return sliderViews.length - 1;
            } else {
                return prevIndex - 1;
            }
        });
    };

    useEffect(() => {
        console.log('current index: ', currentIndex);
    }, [currentIndex]);
    
    return (
        <div
            className={componentStyles.sliderContainer}
            // onTouchStart={handleTouchStart}
            // onTouchMove={handleTouchMove}
            // onTouchEnd={handleTouchEnd}
            // onMouseDown={handleMouseDown}
            // onMouseMove={handleMouseMove}
            // onMouseUp={handleMouseUp}
        >
            {sliderViews.length > 1 && (
                <div className={componentStyles.sliderHeader}>
                    <button className={globalStyles.smallBtn} onClick={handlePrevious}> <FaAngleLeft /> </button>
                    <button className={globalStyles.smallBtn} onClick={handleNext}> <FaAngleRight /> </button>
                </div>
            )}
            <div className={componentStyles.sliderContent}>
                {sliderViews[currentIndex]}
            </div>
        </div>
    );
};

export default SliderView; 