import React, { useState, useEffect } from 'react';
import componentStyles from './home.module.css'; // Import the CSS file for styling
import globalStyles from '../globals.module.css';
import BetFeed from '../betFeed/betFeed';
import Search from '../search/search';
import Settings from '../settings/settings';
import Profile from '../profile/profile';
import Login from '../login/login';
import Register from '../login/register';
import eventBus from '../../service/eventBus';
import { EVENT_UPDATE_ACTION_BAR_VIEW, EVENT_UPDATE_AUTHENTICATED } from '../../utils/constants';
import Landing from '../landing/landing';
import SessionService from '../../service/sessionService';
import { authenticateUser } from '../../service/apiService';
const Home = () => {
    const [currentView, setCurrentView] = useState('feed'); // Default view is feed
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const handleViewChange = (view) => {
        console.log(`Home: Event ${EVENT_UPDATE_ACTION_BAR_VIEW} received with value`, view);
        setCurrentView(view);
    }

    const handleAuthenticated = (isAuthenticated) => {
        console.log(`Home: Event ${EVENT_UPDATE_AUTHENTICATED} received with value`, isAuthenticated);
        if(isAuthenticated) {
            setIsAuthenticated(true);
            setCurrentView('feed');
        }
    }

    useEffect(() => {
        // eventBus.on(EVENT_TRIGGER_IN_DEPTH_BET_CARD, updateIsBetDataCardOpen);
        // eventBus.on(EVENT_UPDATE_IN_DEPTH_BET_CARD, updateBetDataCard);
        //setup event listeners
        eventBus.on(EVENT_UPDATE_ACTION_BAR_VIEW, handleViewChange);
        eventBus.on(EVENT_UPDATE_AUTHENTICATED, handleAuthenticated);

        const validateIsAuthenticated = async () => {
            const isAuthenticated = SessionService.getInstance().isAuthenticated();
            if (isAuthenticated) {
                const sessionId = SessionService.getInstance().getSessionId();
                authenticateUser(sessionId).then((response) => {
                    const { data } = response;
                    if (data.success) {
                        SessionService.getInstance().saveSession(data.results.sessionToken);
                        SessionService.getInstance().setUser(data.results.user);
                        SessionService.getInstance().setIsAuthenticated(true);
                        setIsAuthenticated(true);
                        eventBus.emit(EVENT_UPDATE_AUTHENTICATED, true);
                        eventBus.emit(EVENT_UPDATE_ACTION_BAR_VIEW, 'feed');
                        setCurrentView('feed');
                    }
                    else {
                        SessionService.getInstance().clearSession();
                        eventBus.emit(EVENT_UPDATE_AUTHENTICATED, false);
                        console.error(data.error);
                    }
                }).catch((error) => {
                    SessionService.getInstance().clearSession();
                    eventBus.emit(EVENT_UPDATE_AUTHENTICATED, false);
                    console.log(error);
                });
            }
        }
        
        validateIsAuthenticated();
        // Cleanup event listener on component unmount
        return () => {
            eventBus.off(EVENT_UPDATE_ACTION_BAR_VIEW);
            eventBus.off(EVENT_UPDATE_AUTHENTICATED);
        };
    }, []);

    const renderUnauthenticatedView = () => {
        switch(currentView) {
            case 'landing':
                return (
                    <div className={globalStyles.viewContainer}>
                        <Landing />
                    </div>
                );
            case 'login':
                return (
                    <div className={globalStyles.viewContainer}>
                        <Login />
                    </div>
                );
            case 'register':
                return (
                    <div className={globalStyles.viewContainer}>
                        <Register />
                    </div>
                );
            default:
                return (
                    <div className={globalStyles.viewContainer}>
                        <Landing />
                    </div>
                );
        }
    }

    const renderAuthenticatedView = () => {
        // Render the appropriate component based on currentView
        switch (currentView) {
            case 'feed':
            return (
                <div className={globalStyles.viewContainer} id="feed-view">
                    <BetFeed />
                </div>
            );
        case 'search':
            return (
                <div className={globalStyles.viewContainer}>
                    <Search />
                </div>
            );
        case 'settings':
            return (
                <div className={globalStyles.viewContainer}>
                    <Settings />
                </div>
            );
        case 'profile':
            return (
                <div className={globalStyles.viewContainer}>
                    <Profile />
                </div>
            );
        case 'landing':
            return (
                <div className={globalStyles.viewContainer}>
                    <Landing />
                </div>
            );
        default:
            return (
                <div className={globalStyles.viewContainer}>
                    <BetFeed />
                </div>
            );
        }
    }

    return isAuthenticated ? renderAuthenticatedView() : renderUnauthenticatedView();
};
export default Home; 