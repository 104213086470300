import { useState } from 'react';
import { joinWaitlist } from '../../service/apiService';
import componentStyles from './landing.module.css';
import globalStyles from '../globals.module.css';
import { FaChartLine, FaBell, FaRobot, FaSearch } from 'react-icons/fa';

const Landing = () => {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState('');

    const handleWaitlistSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setStatus('');

        try {
            const response = await joinWaitlist(email);
            if (response.data.success) {
                setStatus('success');
                setEmail('');
            } else {
                setStatus('error');
            }
        } catch (error) {
            setStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className={componentStyles.landingContainer}>
            {/* Hero Section */}
            <section className={componentStyles.heroSection}>
                <h1>Smart Sports Betting Analytics</h1>
                <p className={componentStyles.heroSubtitle}>
                    Make informed betting decisions with AI-powered insights and real-time analytics
                </p>
                <div className={componentStyles.waitlistForm}>
                    <form onSubmit={handleWaitlistSubmit}>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button 
                            type="submit" 
                            className={globalStyles.primaryBtn}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Joining...' : 'Join Waitlist'}
                        </button>
                    </form>
                    {status === 'success' && (
                        <p className={componentStyles.successMessage}>
                            Thanks for joining! We'll be in touch soon.
                        </p>
                    )}
                    {status === 'error' && (
                        <p className={componentStyles.errorMessage}>
                            Something went wrong. Please try again.
                        </p>
                    )}
                </div>
            </section>

            {/* Features Section */}
            <section className={componentStyles.featuresSection}>
                <h2>Why Choose Us?</h2>
                <div className={componentStyles.featuresGrid}>
                    <div className={componentStyles.featureCard + ' ' + globalStyles.slight3dEffect}>
                        <FaChartLine className={componentStyles.featureIcon} />
                        <h3>Advanced Analytics</h3>
                        <p>Deep statistical analysis of player and team performance metrics</p>
                    </div>
                    <div className={componentStyles.featureCard + ' ' + globalStyles.slight3dEffect}>
                        <FaBell className={componentStyles.featureIcon} />
                        <h3>Real-time Alerts</h3>
                        <p>Instant notifications for betting opportunities and line movements</p>
                    </div>
                    <div className={componentStyles.featureCard + ' ' + globalStyles.slight3dEffect}>
                        <FaRobot className={componentStyles.featureIcon} />
                        <h3>AI Predictions</h3>
                        <p>Machine learning models trained on historical data for accurate predictions</p>
                    </div>
                    <div className={componentStyles.featureCard + ' ' + globalStyles.slight3dEffect}>
                        <FaSearch className={componentStyles.featureIcon} />
                        <h3>Custom Research</h3>
                        <p>Personalized betting insights based on your preferences</p>
                    </div>
                </div>
            </section>

            {/* Pricing Section */}
            <section className={componentStyles.pricingSection}>
                <h2>Choose Your Plan</h2>
                <div className={componentStyles.pricingGrid}>
                    <div className={componentStyles.pricingCard}>
                        <h3>Free</h3>
                        <p className={componentStyles.price}>$0/month</p>
                        <ul>
                            <li>Basic bet tracking</li>
                            <li>Limited feed access</li>
                            <li>Basic statistics</li>
                        </ul>
                    </div>
                    <div className={`${componentStyles.pricingCard} ${componentStyles.popularPlan}`}>
                        <div className={componentStyles.popularBadge}>Most Popular</div>
                        <h3>Plus</h3>
                        <p className={componentStyles.price}>$9.99/month</p>
                        <ul>
                            <li>Advanced bet tracking</li>
                            <li>Full feed access</li>
                            <li>Advanced statistics</li>
                            <li>Email notifications</li>
                        </ul>
                    </div>
                    <div className={componentStyles.pricingCard}>
                        <h3>Pro</h3>
                        <p className={componentStyles.price}>$19.99/month</p>
                        <ul>
                            <li>Premium bet tracking</li>
                            <li>Real-time updates</li>
                            <li>Advanced analytics</li>
                            <li>Priority support</li>
                            <li>Custom alerts</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* FAQ Section */}
            <section className={componentStyles.faqSection}>
                <h2>Frequently Asked Questions</h2>
                <div className={componentStyles.faqGrid}>
                    <div className={componentStyles.faqItem}>
                        <h3>How accurate are the predictions?</h3>
                        <p>Our AI models are continuously trained on the latest data, achieving high accuracy rates in historical testing.</p>
                    </div>
                    <div className={componentStyles.faqItem}>
                        <h3>What sports do you cover?</h3>
                        <p>We currently cover NBA, NFL, MLB, NHL, MMA, and Soccer, with more sports coming soon.</p>
                    </div>
                    <div className={componentStyles.faqItem}>
                        <h3>Can I cancel my subscription?</h3>
                        <p>Yes, you can cancel your subscription at any time with no questions asked.</p>
                    </div>
                    <div className={componentStyles.faqItem}>
                        <h3>How do I get started?</h3>
                        <p>Join our waitlist to be notified when we launch and get early access to our platform.</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Landing; 