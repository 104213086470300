import { useState, useEffect } from 'react';
import { getUserProfile, updateUserProfile, updateUserTier } from '../../service/apiService';
import componentStyles from './profile.module.css';
import globalStyles from '../globals.module.css';
import eventBus from '../../service/eventBus';
import { EVENT_UPDATE_AUTHENTICATED, EVENT_UPDATE_ACTION_BAR_VIEW } from '../../utils/constants';
import SessionService from '../../service/sessionService';

const TIERS = {
    FREE: {
        name: 'Free',
        price: 0,
        features: ['Basic bet tracking', 'Limited feed access', 'Basic statistics']
    },
    PLUS: {
        name: 'Plus',
        price: 9.99,
        features: ['Advanced bet tracking', 'Full feed access', 'Advanced statistics', 'Email notifications']
    },
    PRO: {
        name: 'Pro',
        price: 19.99,
        features: ['Premium bet tracking', 'Real-time updates', 'Advanced analytics', 'Priority support', 'Custom alerts']
    }
};

const Profile = () => {
    // Profile state
    const [profile, setProfile] = useState({
        username: '',
        email: '',
        currentTier: 'FREE'
    });
    
    // Form states
    const [isEditing, setIsEditing] = useState(false);
    const [showTierModal, setShowTierModal] = useState(false);
    const [selectedTier, setSelectedTier] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateStatus, setUpdateStatus] = useState('');
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    
    // Password update state
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    // Fetch user profile on mount
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getUserProfile();
                if (response.data.success) {
                    setProfile(response.data.profile);
                }
            } catch (error) {
                // setUpdateStatus('Error loading profile'); TODO: use notification component
            }
        };
        fetchProfile();
    }, []);

    // Handle profile update
    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        setIsUpdating(true);
        setUpdateStatus('');
        
        try {
            const response = await updateUserProfile(profile);
            if (response.data.success) {
                setUpdateStatus('Profile updated successfully!');
                setIsEditing(false);
            }
        } catch (error) {
            setUpdateStatus('Error updating profile');
        } finally {
            setIsUpdating(false);
        }
    };

    // Handle password update
    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setUpdateStatus('Passwords do not match');
            return;
        }

        setIsUpdating(true);
        setUpdateStatus('');
        
        try {
            const response = await updateUserProfile({
                currentPassword: passwordData.currentPassword,
                newPassword: passwordData.newPassword
            });
            if (response.data.success) {
                setUpdateStatus('Password updated successfully!');
                setShowPasswordForm(false);
                setPasswordData({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
            }
        } catch (error) {
            setUpdateStatus('Error updating password');
        } finally {
            setIsUpdating(false);
        }
    };

    // Handle tier upgrade
    const handleTierUpgrade = async () => {
        setIsUpdating(true);
        setUpdateStatus('');
        
        try {
            // In a real application, this would integrate with a payment processor
            const response = await updateUserTier({ tier: selectedTier });
            if (response.data.success) {
                setProfile(prev => ({ ...prev, currentTier: selectedTier }));
                setUpdateStatus('Tier upgraded successfully!');
                setShowTierModal(false);
            }
        } catch (error) {
            setUpdateStatus('Error upgrading tier');
        } finally {
            setIsUpdating(false);
        }
    };

    const handleLogout = () => {
        SessionService.getInstance().clearSession();
        eventBus.emit(EVENT_UPDATE_AUTHENTICATED, false);
        eventBus.emit(EVENT_UPDATE_ACTION_BAR_VIEW, 'landing');
    }

    return (
        <div className={componentStyles.profileContainer}>
            <div className={componentStyles.profileHeader}>
                <h3>Profile</h3>
                <button className={globalStyles.primaryBtn} onClick={handleLogout}>Sign Out</button>
            </div>
            {/* Profile Information */}
            <section className={componentStyles.profileSection + ' ' + globalStyles.slight3dEffect}>
                <h2>Account Information</h2>
                {isEditing ? (
                    <form onSubmit={handleProfileUpdate}>
                        <div className={componentStyles.formGroup}>
                            <label>Username:</label>
                            <input
                                type="text"
                                value={profile.username}
                                onChange={(e) => setProfile(prev => ({ ...prev, username: e.target.value }))}
                            />
                        </div>
                        <div className={componentStyles.formGroup}>
                            <label>Email:</label>
                            <input
                                type="email"
                                value={profile.email}
                                onChange={(e) => setProfile(prev => ({ ...prev, email: e.target.value }))}
                            />
                        </div>
                        <div className={componentStyles.buttonGroup}>
                            <button type="submit" className={globalStyles.primaryBtn} disabled={isUpdating}>
                                {isUpdating ? 'Saving...' : 'Save Changes'}
                            </button>
                            <button 
                                type="button" 
                                className={globalStyles.secondaryBtn}
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className={componentStyles.profileInfo}>
                        <p><strong>Username:</strong> {profile.username}</p>
                        <p><strong>Email:</strong> {profile.email}</p>
                        <button 
                            className={globalStyles.primaryBtn}
                            onClick={() => setIsEditing(true)}
                        >
                            Edit Profile
                        </button>
                    </div>
                )}
            </section>

            {/* Password Update Section */}
            <section className={componentStyles.profileSection + ' ' + globalStyles.slight3dEffect}>
                <h2>Password</h2>
                {showPasswordForm ? (
                    <form onSubmit={handlePasswordUpdate}>
                        <div className={componentStyles.formGroup}>
                            <label>Current Password:</label>
                            <input
                                type="password"
                                value={passwordData.currentPassword}
                                onChange={(e) => setPasswordData(prev => ({ 
                                    ...prev, 
                                    currentPassword: e.target.value 
                                }))}
                            />
                        </div>
                        <div className={componentStyles.formGroup}>
                            <label>New Password:</label>
                            <input
                                type="password"
                                value={passwordData.newPassword}
                                onChange={(e) => setPasswordData(prev => ({ 
                                    ...prev, 
                                    newPassword: e.target.value 
                                }))}
                            />
                        </div>
                        <div className={componentStyles.formGroup}>
                            <label>Confirm Password:</label>
                            <input
                                type="password"
                                value={passwordData.confirmPassword}
                                onChange={(e) => setPasswordData(prev => ({ 
                                    ...prev, 
                                    confirmPassword: e.target.value 
                                }))}
                            />
                        </div>
                        <div className={componentStyles.buttonGroup}>
                            <button type="submit" className={globalStyles.primaryBtn} disabled={isUpdating}>
                                {isUpdating ? 'Updating...' : 'Update Password'}
                            </button>
                            <button 
                                type="button" 
                                className={globalStyles.secondaryBtn}
                                onClick={() => setShowPasswordForm(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                ) : (
                    <button 
                        className={globalStyles.primaryBtn}
                        onClick={() => setShowPasswordForm(true)}
                    >
                        Change Password
                    </button>
                )}
            </section>

            {/* Subscription Tier Section */}
            <section className={componentStyles.profileSection + ' ' + globalStyles.slight3dEffect}>
                <h2>Subscription Tier</h2>
                <div className={componentStyles.tierInfo}>
                    <p><strong>Current Tier:</strong> {TIERS[profile.currentTier].name}</p>
                    <button 
                        className={globalStyles.primaryBtn}
                        onClick={() => setShowTierModal(true)}
                    >
                        Upgrade Tier
                    </button>
                </div>
            </section>

            {/* Status Message */}
            {updateStatus && (
                <div className={componentStyles.statusMessage}>
                    {updateStatus}
                </div>
            )}

            {/* Tier Upgrade Modal */}
            {showTierModal && (
                <div className={componentStyles.modalOverlay}>
                    <div className={componentStyles.modal}>
                        <h2>Choose Your Plan</h2>
                        <div className={componentStyles.tierGrid}>
                            {Object.entries(TIERS).map(([key, tier]) => (
                                <div 
                                    key={key}
                                    className={`${componentStyles.tierCard} ${globalStyles.slight3dEffect} ${
                                        selectedTier === key ? componentStyles.selectedTier : ''
                                    }`}
                                    onClick={() => setSelectedTier(key)}
                                >
                                    <h3>{tier.name}</h3>
                                    <p className={componentStyles.price}>
                                        ${tier.price}/month
                                    </p>
                                    <ul>
                                        {tier.features.map((feature, index) => (
                                            <li key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <div className={componentStyles.modalButtons}>
                            <button 
                                className={globalStyles.primaryBtn}
                                onClick={handleTierUpgrade}
                                disabled={!selectedTier || isUpdating}
                            >
                                {isUpdating ? 'Processing...' : 'Confirm Upgrade'}
                            </button>
                            <button 
                                className={globalStyles.secondaryBtn}
                                onClick={() => setShowTierModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile; 