import { useState, useEffect, useRef } from 'react';
import BetCard from './betCard';
import componentStyles from './betFeed.module.css';
import { getFeed } from '../../service/apiService';
import eventBus from '../../service/eventBus';
import { EVENT_FEED_LOCKED } from '../../utils/constants';

const BetFeed = () => {
    const [displayedCards, setDisplayedCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const feedRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(25);
    const [isFeedLocked, setIsFeedLocked] = useState(false);

    // ✅ Load more cards and trigger pagination
    const loadMoreCards = () => {
        if (!loading) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    // ✅ Fetch feed when `currentPage` updates
    useEffect(() => {
        eventBus.on(EVENT_FEED_LOCKED, (locked) => {
            setIsFeedLocked(locked);
        });

        const fetchFeed = async () => {
            setLoading(true);
            console.log('betFeed::fetchFeed -> Fetching page:', currentPage);

            const request = {
                feed_request_id: '1234567890',
                filters: null,
                page: currentPage,
                page_size: pageSize,
            };

            try {
                console.log('betFeed::fetchFeed -> request:', request);
                const response = await getFeed(request);
                const { data } = response;

                if (data.success) {
                    setDisplayedCards((prevCards) => [
                        ...prevCards,
                        ...data.results.feedItems.map((card, index) => ({
                            ...card, id: prevCards.length + index + 1
                        })),
                    ]);
                }
            } catch (error) {
                console.error('Error fetching feed:', error);
            } finally {
                setTimeout(() => setLoading(false), 3000);
            }
        };

        const handleScroll = () => {
            if (feedRef.current && !isFeedLocked) {
                const { scrollTop, scrollHeight, clientHeight } = feedRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 5) {
                    loadMoreCards();
                }
            }
        };

        const feedElement = feedRef.current;
        if (feedElement) {
            feedElement.addEventListener('scroll', handleScroll);
        }

        fetchFeed(); // Fetch feed data on initial load
        return () => {
            if (feedElement) {
                feedElement.removeEventListener('scroll', handleScroll);
            }
            eventBus.off(EVENT_FEED_LOCKED);
        };
    }, [currentPage]);

    return (
        <div 
            className={componentStyles.betFeedContainer}
            ref={feedRef}
            style={{
                overflowY: isFeedLocked ? 'hidden' : 'auto'
            }}
        >
            <div className={componentStyles.betFeed}>
                {displayedCards.map((card) => (
                    <BetCard key={card.id} betCard={card} />
                ))}
                {loading && <p>Loading more bets...</p>}
            </div>
        </div>
    );
};

export default BetFeed;