import React, { useState } from 'react';
import { login } from '../../service/apiService';
import AppState from '../../model/AppState';
import SessionService from '../../service/sessionService';
import { useNavigate } from 'react-router-dom';
import componentStyles from './login.module.css';
import globalStyles from '../globals.module.css';
import NotificationModel from '../../model/notificationModel';
import { 
    NOTIFICATIONS_SUCCESS, 
    NOTIFICATIONS_ERROR, 
    EVENT_NOTIFICATION_PUSH, 
    EVENT_UPDATE_ACTION_BAR_VIEW, 
    EVENT_UPDATE_AUTHENTICATED } from '../../utils/constants';
import eventBus from '../../service/eventBus';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sessionService, setSessionService] = useState(SessionService.getInstance());

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await login(email, password);
            const { data } = response;

            if (data.success) {
                const {results} = data;
                sessionService.saveSession(results.sessionToken);
                sessionService.setUser(results.user);
                sessionService.setUserSettings(results.userSettings);
                sessionService.setIsAuthenticated(true);
                sessionService.persistSession();

                eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                    new NotificationModel('Login successful!', NOTIFICATIONS_SUCCESS)
                );
                eventBus.emit(EVENT_UPDATE_AUTHENTICATED, true);
                eventBus.emit(EVENT_UPDATE_ACTION_BAR_VIEW, 'feed');
            } else {
                eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                    new NotificationModel('Login failed. Please check your credentials.', NOTIFICATIONS_ERROR)
                );
            }
        } catch (error) {
            eventBus.emit(EVENT_NOTIFICATION_PUSH, 
                new NotificationModel('An error occurred during login.', NOTIFICATIONS_ERROR)
            );
            console.error('Login error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRegisterClick = () => {
        eventBus.emit(EVENT_UPDATE_ACTION_BAR_VIEW, 'register');
    };

    return (
        <div className={componentStyles.loginContainer}>
            <div className={`${componentStyles.loginCard} ${globalStyles.slight3dEffect}`}>
                <h2>Login to Your Account</h2>
                <form onSubmit={handleSubmit} className={componentStyles.loginForm}>
                    <div className={componentStyles.formGroup}>
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                            className={componentStyles.input}
                        />
                    </div>
                    <div className={componentStyles.formGroup}>
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                            className={componentStyles.input}
                        />
                    </div>
                    <button 
                        type="submit" 
                        className={globalStyles.primaryBtn}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
                <div className={componentStyles.registerPrompt}>
                    <p>Don't have an account?</p>
                    <button 
                        onClick={handleRegisterClick}
                        className={globalStyles.secondaryBtn}
                    >
                        Register
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login; 